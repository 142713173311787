<template>
  <div class="dashboard">
    <div class="title-section">
      <h1 class="title">
        Weekly Perf
      </h1>

      <div class="export-filters">
        <export :grid-api="api || undefined" />
      </div>
    </div>
    <section class="content">
      <filters
        :allow-future="false"
        :allow-granularity="false"
        :allow-date-range="false"
        :variant-options="variantOptions"
        :variant-options2="variantOptions2"
      />
      <section>
        <grid
          :columns="columns"
          :default-hidden-columns="defaultHiddenColumns"
          :data="kpis"
          :loading="$apollo.loading"
          :auto-group-column="autoGroupColumnDef"
          :show-footer="false"
          :group-default-expanded="1"
          :side-bar="sidebar"
          @ready="api = $event"
        />
      </section>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as moment from 'moment';
import { GET_WEEKLY_PERF } from '../gql-requests';
import GridMixin from '../mixins/GridMixin';
import Export from '../components/weeklyPerf/Export';

export default {
  components: { Export },
  mixins: [GridMixin],
  data() {
    return {
      api: null,
      weeklyPerfData: [],
      rawKpis: {},
      aggregationKpis: {},
      variantOptions: [
        {
          key: 'country',
          name: 'KPI',
        },
        {
          key: 'kpi',
          name: 'Country',
        },
      ],
      variantOptions2: {
        title: 'Month Selection',
        key: 'month-selection',
        list: [
          {
            key: 'current',
            name: 'Current',
          },
          {
            key: 'previous',
            name: 'Previous one',
          },
        ],
      },
      sidebar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true,
              suppressRowGroups: true,
              suppressValues: true,
              contractColumnSelection: true,
            },
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
            toolPanelParams: {
              suppressExpandAll: false,
              suppressFilterSearch: true,
            },
            minWidth: 180,
            maxWidth: 400,
            width: 250,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters('filters', ['getDateRange', 'getVariant', 'getVariant2']),
    autoGroupColumnDef() {
      return {
        headerName: 'KPI',
        pinned: 'left',
        resizable: true,
        suppressMovable: true,
        cellRendererParams: {
          suppressCount: true,
          innerRenderer: (params) => (params.data?.metabaseUrl && params.value !== 'ARPES' ? params.data?.metabaseUrl : params.value),
        },
        valueGetter: (params) => {
          if (params.data?.appName === 'Total' && this.isVariantCountry) {
            return params.data?.kpi;
          }
          const field = this.isVariantCountry ? 'country' : 'kpi';
          return params.data?.[field];
        },
      };
    },
    isVariantCountry() {
      return this.getVariant === 'country';
    },
    isVariantCurrentMonth() {
      return this.getVariant2['month-selection'] === 'current';
    },
    timePeriods() {
      const dates = Array.from(
        new Set(
          this.weeklyPerfData
            ?.flatMap((weeklyData) => weeklyData?.weeklyData?.map((period) => period.date) ?? [])
            ?.filter(Boolean) ?? [],
        ),
      );

      const fieldFormat = 'YYYYMMDD';
      const labelFormat = 'MMM Do YYYY';
      const labelShortFormat = 'DD-MMM';

      return dates
        .sort()
        .map((date) => {
          const timePeriod = moment(date);
          return {
            field: timePeriod.format(fieldFormat),
            label: timePeriod.format(labelFormat),
            labelShort: timePeriod.format(labelShortFormat),
          };
        });
    },
    lastPeriod() {
      return Math.max(...this.timePeriods.map((period) => period.field));
    },
    previousMonthPeriod() {
      return [...new Set(this.timePeriods.map((period) => period.field.substring(0, 6)))].sort().reverse()[1];
    },
    lastMonthPeriod() {
      const dateString = this.lastPeriod.toString();

      return dateString.substring(0, 6);
    },
    defaultHiddenColumns() {
      const columns = [];
      this.timePeriods.forEach((period) => {
        const isCurrentMonth = this.lastMonthPeriod === this.getMonthPeriod(period.field);
        const isPreviousMonth = this.previousMonthPeriod === this.getMonthPeriod(period.field);

        if (isCurrentMonth || isPreviousMonth) {
          const hide = isCurrentMonth ? !this.isVariantCurrentMonth : this.isVariantCurrentMonth;

          if (!hide) {
            columns.push(`week.${period.field}.target`, `week.${period.field}.targetDiff`, `week.${period.field}.targetDiffPercent`);
          }
        }
      });

      return columns;
    },
    columns() {
      const columns = [];

      columns.push({
        headerName: 'App',
        field: 'appName',
        colId: 'appName',
        filter: true,
        rowGroup: true,
        hide: true,
        enableRowGroup: true,
      });

      columns.push({
        headerName: this.isVariantCountry ? 'KPI' : 'Country',
        colId: this.isVariantCountry ? 'kpi' : 'country',
        hide: true,
        rowGroup: true,
        enableRowGroup: true,
        valueGetter: (params) => {
          if (params.data?.appName === 'Total' && this.isVariantCountry) {
            return undefined;
          }
          const field = this.isVariantCountry ? 'kpi' : 'country';
          return params.data?.[field];
        },
      });

      this.timePeriods.forEach((period) => {
        const currentPeriodNumeric = period.field * 1;
        const headerClass = currentPeriodNumeric === this.lastPeriod ? 'current-month-header' : '';

        const isCurrentMonth = this.lastMonthPeriod === this.getMonthPeriod(period.field);
        const isPreviousMonth = this.previousMonthPeriod === this.getMonthPeriod(period.field);

        if (isCurrentMonth || isPreviousMonth) {
          const hide = isCurrentMonth ? !this.isVariantCurrentMonth : this.isVariantCurrentMonth;

          columns.push({
            headerName: period.label,
            headerClass,
            children: [
              {
                headerName: 'Data',
                headerClass,
                children: [
                  {
                    headerName: 'Actual',
                    field: `${period.field}.w`,
                    colId: `week.${period.field}.w`,
                    type: 'numericColumn',
                    aggFunc: this.customAggregation,
                    resizable: true,
                    width: 110,
                    hide,
                    headerClass,
                    valueFormatter: (val) => {
                      const kpi = val.data?.kpi ?? val.node?.key;
                      return this.formatKpi(val.value, kpi);
                    },
                  },
                  {
                    headerName: 'Target',
                    field: `${period.field}.target`,
                    colId: `week.${period.field}.target`,
                    type: 'numericColumn',
                    aggFunc: this.customAggregation,
                    resizable: true,
                    width: 110,
                    hide,
                    headerClass,
                    valueFormatter: (val) => {
                      const kpi = val.data?.kpi ?? val.node?.key;
                      return this.formatKpi(val.value, kpi);
                    },
                  },
                ],
              },
              {
                headerName: 'Diff',
                headerClass,
                children: [
                  {
                    headerName: 'Value',
                    field: `${period.field}.targetDiff`,
                    colId: `week.${period.field}.targetDiff`,
                    type: 'numericColumn',
                    aggFunc: this.customAggregation,
                    resizable: true,
                    width: 110,
                    hide,
                    headerClass,
                    valueFormatter: (val) => {
                      const kpi = val.data?.kpi ?? val.node?.key;
                      return this.formatKpi(val.value, kpi, true);
                    },
                  },
                  {
                    headerName: '%',
                    field: `${period.field}.targetDiffPercent`,
                    colId: `week.${period.field}.targetDiffPercent`,
                    type: 'numericColumn',
                    aggFunc: this.customAggregation,
                    resizable: true,
                    width: 110,
                    hide,
                    headerClass,
                    cellStyle: (params) => this.cellStyle(params),
                  },
                ],
              },
            ],
          });
        }
      });

      const dailyPerfColumns = [];
      this.timePeriods.forEach((period) => {
        const isCurrentMonth = this.lastMonthPeriod === this.getMonthPeriod(period.field);
        const isPreviousMonth = this.previousMonthPeriod === this.getMonthPeriod(period.field);

        if (isCurrentMonth || isPreviousMonth) {
          const hide = isCurrentMonth ? !this.isVariantCurrentMonth : this.isVariantCurrentMonth;

          const column = {
            headerName: period.labelShort,
            field: `${period.field}.dailyValueW`,
            colId: `week.${period.field}.dailyValueW`,
            type: 'numericColumn',
            aggFunc: this.customAggregation,
            resizable: true,
            width: 110,
            hide,
            valueFormatter: (val) => {
              const kpi = val.data?.kpi ?? val.node?.key;
              return this.formatKpi(val.value, kpi);
            },
          };
          dailyPerfColumns.push(column);
        }
      });

      columns.push({
        headerName: 'Daily Perf',
        children: [
          {
            headerName: 'Week ending - Daily Value',
            children: dailyPerfColumns,
          },
          // {
          //   headerName: 'Var vs Prior Week',
          //   children: [
          //     {
          //       headerName: '24-Feb',
          //       resizable: true,
          //       width: 110,
          //     },
          //     {
          //       headerName: '17-Feb',
          //       resizable: true,
          //       width: 110,
          //     },
          //   ],
          // },
        ],
      });

      return columns;
    },
    kpis() {
      return Object.values(this.rawKpis).map((item) => ({ ...item }));
    },
    dataChange() {
      const { weeklyPerfData } = this;
      if (weeklyPerfData) {
        return {
          weeklyPerfData,
        };
      }
      return null;
    },
  },
  watch: {
    weeklyPerfData() {
      this.formatKpiAgGrid();
    },
    dataChange: {
      handler(val) {
        if (val) {
          this.formatKpiAgGrid();
        }
      },
      deep: true,
    },
  },
  methods: {
    getMonthPeriod(period) {
      return period && period.substring(0, 6);
    },
    customAggregation(params) {
      const { values, rowNode, column } = params;
      const kpi = rowNode?.key;
      const app = rowNode?.parent?.key;
      const field = column?.colDef?.field;

      if (rowNode.level > 0 && values.filter(Boolean).length > 0) {
        const [month, property] = field.split('.');
        return this.aggregationKpis?.[app]?.[kpi]?.[month]?.[property];
      }

      return undefined;
    },
    formatCompletion(value) {
      if (!value) {
        return undefined;
      }

      const sign = value > 0 ? '+' : '-';

      const amount = Math.abs(value * 100)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

      return `${sign} ${amount} %`;
    },
    formatDate(input) {
      const date = new Date(input);
      return `${date.getFullYear()}${this.withLeadingZero(date.getMonth() + 1)}${this.withLeadingZero(date.getDate())}`;
    },
    cellStyle(params) {
      const key = (params?.data?.kpi ?? params?.node?.key)?.toLowerCase?.();
      const positiveSign = ['churn', 'cpa', 'cpi', 'spent'].includes(key) ? '-' : '+';
      const negativeSign = ['churn', 'cpa', 'cpi', 'spent'].includes(key) ? '+' : '-';

      if (params?.value?.startsWith(negativeSign)) {
        return { color: 'red' };
      }
      if (params?.value?.startsWith(positiveSign)) {
        return { color: 'green' };
      }

      return { color: 'orange' };
    },
    formatKpiAgGrid() {
      const rowData = {};
      const aggregationData = {};

      this.weeklyPerfData
        .forEach((entry) => {
          const {
            appName, kpi, country, weeklyData,
          } = entry;

          const key = `${appName}-${kpi}-${country}`;

          if (country !== null) {
            if (!rowData[key]) {
              const metabaseUrl = kpi === 'ARPES'
                ? `<a target="_blank" href="https://metabase.tinylab.io/dashboard/694-pricing-analysis?app=${appName}&geo=${country}&billing_period=30&date=past10weeks">${country}</a>`
                : undefined;
              rowData[key] = {
                appName,
                kpi,
                country: country !== 'All' ? country : undefined,
                metabaseUrl,
              };
            }

            weeklyData
              .filter((weekly) => weekly.date)
              .forEach((weekly) => {
                const weekDate = this.formatDate(weekly.date);

                rowData[key][weekDate] = {
                  w: weekly.value,
                  target: weekly.target,
                  targetDiff: weekly.targetDiff,
                  targetDiffPercent: this.formatCompletion(weekly.targetDiffPercent),
                  wowW: weekly.weekOverWeek,
                  wowPercW: this.formatCompletion(weekly.weekOverWeekPercent),
                  dailyValueW: weekly.dailyValueW,
                };
              });
          } else {
            if (!aggregationData[appName]) {
              aggregationData[appName] = {};
            }

            if (!aggregationData[appName][kpi]) {
              aggregationData[appName][kpi] = {};
            }

            weeklyData
              .filter((weekly) => weekly.date)
              .forEach((weekly) => {
                const weekDate = this.formatDate(weekly.date);

                aggregationData[appName][kpi][weekDate] = {
                  w: weekly.value,
                  target: weekly.target,
                  targetDiff: weekly.targetDiff,
                  targetDiffPercent: this.formatCompletion(weekly.targetDiffPercent),
                  wowW: weekly.weekOverWeek,
                  wowPercW: this.formatCompletion(weekly.weekOverWeekPercent),
                  dailyValueW: weekly.dailyValueW,
                };
              });
          }
        });

      this.rawKpis = rowData;
      this.aggregationKpis = aggregationData;
    },
  },
  apollo: {
    weeklyPerfData: {
      query: GET_WEEKLY_PERF,
      skip() {
        return !this.getDateRange.length;
      },
      variables() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  display: flex;
  flex-direction: column;
  height: 100%;

  .filters {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  .content {
    flex: 1;
    min-height: 1px;
    display: flex;
    flex-direction: column;
    max-height: 100%;

    .content-wrapper {
      flex: 1;
      display: flex;
      max-height: 100%;
    }
  }

  .week-picker ::v-deep .dropdown .datepicker-content .datepicker-body .datepicker-row:hover {
    background: #f2f2f2;

    & .datepicker-cell {
      border-radius: 0;

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  .week-picker .datepicker-content .datepicker-body.datepicker-row {
    background: green;
  }

  .weekly-date {
    display: flex;
    align-items: center;

    & > :first-child {
      margin-right: 10px;
    }
  }
}

.current-month-header {
  background-color: #f2effb;
  color: #552fbc;
}

.export-filters {
  display: flex;
  align-items: center;

  & > * {
    margin-left: 10px;
  }
}
</style>
