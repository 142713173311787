<template>
  <div class="dashboard">
    <div class="title-section">
      <h1 class="title">
        Studio Perf
      </h1>

      <!--      <div class="export-filters">-->
      <!--        <export :grid-api="api || undefined" />-->
      <!--      </div>-->
    </div>
    <section class="content">
      <filters
        :allow-future="false"
        :allow-granularity="false"
        :allow-date-range="false"
        :variant-options="variantOptions"
      />
      <section>
        <grid
          :columns="columns"
          :default-hidden-columns="defaultHiddenColumns"
          :data="kpis"
          :loading="$apollo.loading"
          :auto-group-column="autoGroupColumnDef"
          :show-footer="false"
          :group-default-expanded="1"
          :side-bar="sidebar"
          @ready="api = $event"
        />
      </section>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as moment from 'moment';
import { GET_STUDIO_PERF } from '../gql-requests';
import GridMixin from '../mixins/GridMixin';
// import Export from '../components/weeklyPerf/Export';

export default {
  // components: { Export },
  mixins: [GridMixin],
  data() {
    return {
      api: null,
      studioPerfData: [],
      rawKpis: {},
      aggregationKpis: {},
      variantOptions: [],
      sidebar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true,
              suppressRowGroups: true,
              suppressValues: true,
              contractColumnSelection: true,
            },
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
            toolPanelParams: {
              suppressExpandAll: false,
              suppressFilterSearch: true,
            },
            minWidth: 180,
            maxWidth: 400,
            width: 250,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters('filters', ['getDateRange', 'getVariant']),
    autoGroupColumnDef() {
      return {
        headerName: 'App',
        field: 'appName',
        pinned: 'left',
        resizable: true,
        suppressMovable: true,
        cellRendererParams: {
          suppressCount: true,
        },
        valueGetter: (params) => {
          if (params.data?.appName === 'Total') {
            return params.data?.kpi;
          }
          const field = 'appName';
          return params.data?.[field];
        },
      };
    },
    timePeriods() {
      const year = this.studioPerfData?.[0]?.currentYear;
      const month = this.studioPerfData?.[0]?.currentMonth;
      const dates = Array.from(
        new Set(
          this.studioPerfData
            ?.flatMap((monthlyData) => monthlyData?.monthlyData?.filter((period) => period.value !== null)?.map((period) => period.month) ?? [])
          ?? [],
        ),
      );

      const fieldFormat = 'YYYYMM';
      const labelFormat = 'MMMM YYYY';
      const labelShortFormat = 'MM-YYYY';

      return dates
        .sort((a, b) => b - a)
        .map((date) => {
          const timePeriod = moment(new Date(year, month - 1)).subtract(date, 'months');
          return {
            field: timePeriod.format(fieldFormat),
            label: timePeriod.format(labelFormat),
            labelShort: timePeriod.format(labelShortFormat),
          };
        });
    },
    defaultHiddenColumns() {
      const columns = [];
      this.timePeriods.forEach((period) => {
        columns.push(`month.${period.field}.target`, `month.${period.field}.targetDiff`, `month.${period.field}.targetDiffPercent`);
      });

      return columns;
    },
    columns() {
      const columns = [];

      columns.push({
        headerName: 'Studio',
        field: 'studio',
        colId: 'studio',
        filter: true,
        rowGroup: true,
        hide: true,
        enableRowGroup: true,
      });

      columns.push({
        headerName: 'KPI',
        colId: 'kpi',
        field: 'kpi',
        hide: true,
        rowGroup: true,
        enableRowGroup: true,
        valueGetter: (params) => {
          if (params.data?.appName === 'Total') {
            return undefined;
          }
          const field = 'kpi';
          return params.data?.[field];
        },
      });

      this.timePeriods.forEach((period) => {
        const currentPeriodNumeric = period.field * 1;
        const headerClass = currentPeriodNumeric === this.lastPeriod ? 'current-month-header' : '';
        const hide = false;

        columns.push({
          headerName: period.label,
          colId: currentPeriodNumeric,
          headerClass,
          children: [
            {
              headerName: 'Data',
              headerClass,
              children: [
                {
                  headerName: 'Actual',
                  field: `${period.field}.actual`,
                  colId: `month.${period.field}.actual`,
                  type: 'numericColumn',
                  aggFunc: this.customAggregation,
                  resizable: true,
                  width: 110,
                  hide,
                  headerClass,
                  valueFormatter: (val) => {
                    const kpi = val.data?.kpi ?? val.node?.key;
                    return this.formatKpi(val.value, kpi);
                  },
                },
                {
                  headerName: 'Target',
                  field: `${period.field}.target`,
                  colId: `month.${period.field}.target`,
                  type: 'numericColumn',
                  aggFunc: this.customAggregation,
                  resizable: true,
                  width: 110,
                  hide,
                  headerClass,
                  valueFormatter: (val) => {
                    const kpi = val.data?.kpi ?? val.node?.key;
                    return this.formatKpi(val.value, kpi);
                  },
                },
              ],
            },
            {
              headerName: 'Diff',
              headerClass,
              children: [
                {
                  headerName: 'Value',
                  field: `${period.field}.targetDiff`,
                  colId: `month.${period.field}.targetDiff`,
                  type: 'numericColumn',
                  aggFunc: this.customAggregation,
                  resizable: true,
                  width: 110,
                  hide,
                  headerClass,
                  valueFormatter: (val) => {
                    const kpi = val.data?.kpi ?? val.node?.key;
                    return this.formatKpi(val.value, kpi, true);
                  },
                },
                {
                  headerName: '%',
                  field: `${period.field}.targetDiffPercent`,
                  colId: `month.${period.field}.targetDiffPercent`,
                  type: 'numericColumn',
                  aggFunc: this.customAggregation,
                  resizable: true,
                  width: 110,
                  hide,
                  headerClass,
                  cellStyle: (params) => this.cellStyle(params),
                },
              ],
            },
          ],
        });
      });

      return columns;
    },
    kpis() {
      return Object.values(this.rawKpis).map((item) => ({ ...item }));
    },
    dataChange() {
      const { studioPerfData } = this;
      if (studioPerfData) {
        return {
          studioPerfData,
        };
      }
      return null;
    },
  },
  watch: {
    studioPerfData() {
      this.formatKpiAgGrid();
    },
    dataChange: {
      handler(val) {
        if (val) {
          this.formatKpiAgGrid();
        }
      },
      deep: true,
    },
  },
  methods: {
    customAggregation(params) {
      const { values, rowNode, column } = params;
      const kpi = rowNode?.key;
      const app = rowNode?.parent?.key;
      const field = column?.colDef?.field;

      if (rowNode.level > 0 && values.filter(Boolean).length > 0) {
        const [month, property] = field.split('.');
        return this.aggregationKpis?.[app]?.[kpi]?.[month]?.[property];
      }

      return undefined;
    },
    formatCompletion(value) {
      if (!value) {
        return undefined;
      }

      const sign = value > 0 ? '+' : '-';

      const amount = Math.abs(value * 100)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

      return `${sign} ${amount} %`;
    },
    formatDate(year, month, input) {
      const timePeriod = moment(new Date(year, month - 1)).subtract(input, 'months');
      return timePeriod.format('YYYYMM');
    },
    cellStyle(params) {
      const key = (params?.data?.kpi ?? params?.node?.key)?.toLowerCase?.();
      const positiveSign = ['churn', 'cpa', 'cpi', 'spent'].includes(key) ? '-' : '+';
      const negativeSign = ['churn', 'cpa', 'cpi', 'spent'].includes(key) ? '+' : '-';

      if (params?.value?.startsWith(negativeSign)) {
        return { color: 'red' };
      }
      if (params?.value?.startsWith(positiveSign)) {
        return { color: 'green' };
      }

      return { color: 'orange' };
    },
    formatKpiAgGrid() {
      const rowData = {};
      const aggregationData = {};

      this.studioPerfData
        .forEach((entry) => {
          const {
            studio, appName, kpi, monthlyData,
            currentYear, currentMonth,
          } = entry;

          const key = `${studio}-${kpi}-${appName}`;

          if (appName !== 'Total') {
            if (!rowData[key]) {
              rowData[key] = {
                studio,
                kpi,
                appName,
              };
            }

            monthlyData
              .filter((monthly) => monthly.value)
              .forEach((monthly) => {
                const monthDate = this.formatDate(currentYear, currentMonth, monthly.month);

                rowData[key][monthDate] = {
                  actual: monthly.value,
                  target: monthly.target,
                  targetDiff: monthly.targetDiff,
                  targetDiffPercent: this.formatCompletion(monthly.targetDiffPercent),
                };
              });
          } else {
            if (!aggregationData[studio]) {
              aggregationData[studio] = {};
            }

            if (!aggregationData[studio][kpi]) {
              aggregationData[studio][kpi] = {};
            }

            monthlyData
              .filter((monthly) => monthly.value)
              .forEach((monthly) => {
                const monthDate = this.formatDate(currentYear, currentMonth, monthly.month);

                aggregationData[studio][kpi][monthDate] = {
                  actual: monthly.value,
                  target: monthly.target,
                  targetDiff: monthly.targetDiff,
                  targetDiffPercent: this.formatCompletion(monthly.targetDiffPercent),
                };
              });
          }
        });

      this.rawKpis = rowData;
      this.aggregationKpis = aggregationData;
    },
  },
  apollo: {
    studioPerfData: {
      query: GET_STUDIO_PERF,
      skip() {
        return !this.getDateRange.length;
      },
      variables() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  display: flex;
  flex-direction: column;
  height: 100%;

  .filters {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  .content {
    flex: 1;
    min-height: 1px;
    display: flex;
    flex-direction: column;
    max-height: 100%;

    .content-wrapper {
      flex: 1;
      display: flex;
      max-height: 100%;
    }
  }

  .week-picker ::v-deep .dropdown .datepicker-content .datepicker-body .datepicker-row:hover {
    background: #f2f2f2;

    & .datepicker-cell {
      border-radius: 0;

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  .week-picker .datepicker-content .datepicker-body.datepicker-row {
    background: green;
  }

  .weekly-date {
    display: flex;
    align-items: center;

    & > :first-child {
      margin-right: 10px;
    }
  }
}

.current-month-header {
  background-color: #f2effb;
  color: #552fbc;
}

.export-filters {
  display: flex;
  align-items: center;

  & > * {
    margin-left: 10px;
  }
}
</style>
